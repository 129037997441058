import React from "react"
import { StaticQuery, graphql } from "gatsby"
import Layout from "../components/layout/fr"
import SEO from "../components/seo"
import HeroImage from "../components/ui-sections/hero-image"
import ImageGallery from "../components/ui-sections/image-gallery"
import TextSection from "../components/ui-sections/text-section"

const CuinaPage = (props) => {
	const heroText = "Cuisine"
	const textSectionText = "Produit saisonnier et local. Nous combinons la cuisine traditionnelle de nos grands-parents avec les nuances gastronomiques avant-gardistes que nous avons aujourd'hui. Nous aimons notre terre et nous avons l'impression de posséder les pois de Llavaneres, le veau de Gérone, les crevettes de Vilanova ou les artichauts d'El Prat. La Gall del Penedès, la pêche de Sant Pau d'Ordal et la tomate Montserrat. Nous nous connaissons privilégiés d'avoir ces trésors à portée de main et notre cuisine est l'expression la plus sincère de notre passion. Nous espérons que vous aimez."
	return (
	  <Layout location={props.location}>
	    <SEO 
	    	title="Cuisine"
	    	lang="fr"
	    	description={textSectionText}
	    />
	    <HeroImage 
	    	titleText={heroText}
	    	image="cuina" 
	    />
	    <TextSection textSectionText={textSectionText} />
	    <StaticQuery
	      query={graphql`
	        # Replace this comment with the GraphQL query above
	        {
	          allCuinaImagesFrYaml {
	            edges {
	              node {
	                title
	                image {
	                	id
	                  childImageSharp {
	                    fluid {
	                      aspectRatio
	                      originalImg
	                      # Use gatsby-image later for better user experience
	                      ...GatsbyImageSharpFluid
	        }}}}}}}
	      `}
	      render={data => (
	        <ImageGallery
	          itemsPerRow={[1, 3]} // This will be changed to `[2, 3]` later
	          images={data.allCuinaImagesFrYaml.edges.map(({ node }) => ({
	          	id: node.image.id,
	            ...node.image.childImageSharp.fluid,
	            caption: `${node.title}`,
	          }))}
	        />
	      )}
	    />
	  </Layout>
	)
}

export default CuinaPage
